<template>
  <vuestic-widget>
    <legend><strong>Create Contact</strong></legend>
    <ValidationObserver v-slot="{ handleSubmit, invalid }">
      <form @submit.prevent="handleSubmit(saveNewContact)">
        <div class="row">
          <div class="col-md-6">
            <text-input name="First Name" v-model="newContactForm.first_name" label="First Name"/>
          </div>
          <div class="col-md-6">
            <text-input name="Last Name" v-model="newContactForm.last_name" label="Last Name"/>
          </div>
          <div class="col-md-6">
            <phone-number-input name="Phone Number" v-model="newContactForm.number" label="Phone Number"/>
          </div>
          <div class="col-md-6">
            <text-input type="email" name="Email" v-model="newContactForm.email" label="Email"/>
          </div>
          <div class="col-md-6">
            <BirthdayInput v-model="newContactForm.bday" />
          </div>
          <div class="col-md-6">
            <BirthdayInput v-model="newContactForm.anniversary" label="Anniversary" />
          </div>
          <div class="col-md-6">
            <text-input name="City" v-model="newContactForm.city" label="City"/>
          </div>
          <div class="col-md-6 mt-4">
            <div class="form-group">
              <div class="input-group">
                <textarea
                  v-model="newContactForm.note"
                  name="Notes"
                  class="form-control w-100"
                  maxlength="299"
                  rows="3"
                ></textarea>
                <label class="control-label" for="Message">Notes</label>
                <i class="bar"></i>
              </div>
            </div>
            <SMSLength :text="newContactForm.note" />
          </div>
        </div>
        <CustomFields v-model="newContactForm.custom_fields" />
        <div class="row">
          <div class="col-md-12 mt-4 mb-4">
            <tag-selector
              v-model="tagsSelected"
            ></tag-selector>
          </div>
          <div class="col-md-12 mb-4">
            <label class="input-label has-tool-tip">Automation <a v-b-tooltip.hover title="Run selected automations when contact is being created." class="fa fa-question-circle tooltip-icon"/></label>
            <multiselect
              :show-labels="false" 
              v-model="selectedAutomation"
              :options="automations"
              :multiple="false"
              :preserve-search="true"
              :allowEmpty="true"
              placeholder="Select Automation"
              label="name"
              track-by="id"
            >
            </multiselect>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-12">
            <div class="custom-checkbox-wrapper d-inline-block">
              <label class="checkbox-label">
                <input type="checkbox" id="confirm-checkbox" v-model="isConfirmed">
                <span class="checkbox-custom"></span>
              </label>
            </div>
            <span class="d-inline-block ml-2" style="cursor: pointer" @click="isConfirmed = !isConfirmed">We have permission to contact this person via text message.</span>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-md-12 d-flex justify-content-center">
            <button class="btn btn-primary" :disabled="processing || invalid">
              <atom-spinner v-if="processing" slot="loading" :animation-duration="1500" :size="14" color="#FFF"/>
              <span v-else>Create</span>
            </button>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </vuestic-widget>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex';
import moment from 'moment'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import components from '../../common/tables/comps'
import TagSelector from '../../common/TagSelector'
import SMSLength from '../../common/SMSLength'
import CustomFields from './CustomFields'

export default {
  components: {
    TagSelector,
    SMSLength,
    CustomFields
  },

  data () {
    return {
      newContactForm: {
        first_name: '',
        last_name: '',
        number: '',
        email: '',
        bday: '',
        anniversary: '',
        city: '',
        note: '',
        tags: [],
        custom_fields: {},
      },
      tagsSelected: [],
      automations: [],
      isConfirmed: false,
      selectedAutomation: null,
    }
  },

  mounted () {
    if (!this.$route.query.install) {
      this.$router.push({ name: 'business.contacts.index' })
    }
    this.loadAutomations();
  },

  methods: {
    loadAutomations() {
      this.$store.dispatch('automation/all')
        .then((res) => {
          this.automations = res.filter(item => item.active)
        })
        .catch(() => {
        })
    },

    saveNewContact () {
      const numberObj = parsePhoneNumberFromString(this.newContactForm.number);
      const type = this.isConfirmed ? 'sms' : 'cms'

      const payload = {
        id: this.$route.query.install,
        data: {
          ...this.newContactForm,
          number: numberObj.number,
          type,
          tags: this.tagsSelected,
          automationId: this.selectedAutomation ? this.selectedAutomation.id: null,
        }
      }
      
      this.$store.dispatch('contact/create', payload).then(() => {
        this.$router.push({ name: 'business.contacts.index' })
      })
    },
  },
  computed: {
    ...mapState('contact', {
      processing: 'processing',
    })
  }
};
</script>

<style scoped lang="scss">
  .createContactForm {
    max-width: 768px;
  }
  .checkbox-label {
    --primaryColor: #3578c6;
  }
  .custom-checkbox-wrapper .checkbox-label .checkbox-custom {
    border-color: $text-gray;
    top: 4px;
    height: 17px;
    width: 17px;
  }

  .custom-checkbox-wrapper .checkbox-label input:checked ~ .checkbox-custom::after {
    left: 5px;
    top: 0px;
  }

  .tag-wrapper {
    span.add-tag-btn {
      display: inline-block;
      border: 1px dashed #ccc;
      border-radius: 5px;
      padding: 2px 5px;
      margin-bottom: 5px;
      cursor: pointer;
      font-size: 14px;
      &:hover {
        border-color: #3578c6;
        color: #3578c6;
      }
    }
    span.tag {
      cursor: pointer;
      background-color: #3578c6;
      color: #fff;
      border-radius: 5px;
      padding: 3px 5px;
      font-size: 0.85em;
      display: block;
      margin-right: 5px;
      display: inline-block;
      margin-bottom: 5px;
      i {
        background-color: #325f94;
        padding: 2px 3px;
        border-radius: 50%;
      }
      &:hover i {
        background-color: #15457d;
      }
    }
  }
</style>